import React, {useState} from 'react';
import * as styles from './button.module.scss';
import {Link} from 'gatsby';
import {
    faChevronRight
} from '@fortawesome/free-solid-svg-icons'
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

const Button = ({to='#', text='Get started', type, extraStyle, onHover}) => {
    const [defaultType, setDefaultType] = useState(type);

    const changeOnHover = () => {
        if(onHover){
            setDefaultType(onHover);
        }
    }
    return (
        <Link onMouseOver={() => changeOnHover()} onMouseLeave={() => setDefaultType(type)} className={`${styles.button} ${defaultType === 'white' ? styles.white : styles.orange}`} to={to} style={extraStyle}>
            {text}
            <FontAwesomeIcon icon={faChevronRight} className={styles.chevronRight}/>
        </Link>
    );
}

export default Button;